import React from 'react';

export interface HelpSvgProps {}

const HelpSvg: React.FunctionComponent<HelpSvgProps> = () => (
  <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.0659 2.2615C17.775 2.15171 16.0855 1.76747 15.6547 1.59182C14.9554 1.30638 14.1498 1.69062 13.8365 2.36579C13.5177 3.05743 13.775 3.86983 14.4407 4.25407C15.1121 4.6438 15.9568 4.4901 16.41 3.86434C16.5722 3.63928 16.7345 3.60086 16.9414 3.72711C17.2435 3.91374 17.5456 4.11135 17.8254 4.33641C17.8981 4.39679 17.9317 4.545 17.9261 4.65478C17.8869 5.49462 17.8421 6.33447 17.775 7.17431C17.7638 7.32801 17.6519 7.50367 17.5345 7.60796C14.7932 10.0067 12.0519 12.4 9.30508 14.7988C8.53865 15.4685 7.77221 16.1382 7.00578 16.8023C6.6813 17.0878 6.55263 17.0768 6.26732 16.7639C4.23655 14.5243 2.21697 12.3012 0.197388 10.0726C-0.0711435 9.77619 -0.0711435 9.64994 0.230954 9.38646C3.75543 6.31251 7.27991 3.23308 10.8156 0.170117C10.9442 0.0603331 11.168 -0.00553713 11.3414 -4.79481e-05C13.4449 0.109736 15.5484 0.235987 17.6519 0.362238C18.0827 0.389684 18.1722 0.483 18.1442 0.905667C18.1163 1.3448 18.0939 1.77845 18.0659 2.2615ZM11.1456 3.45265C10.9778 3.44167 10.8883 3.43069 10.7988 3.43618C9.10928 3.46363 8.07431 5.28055 8.91907 6.73518C9.01976 6.90534 9.01417 7.01513 8.90228 7.17431C7.73865 8.87596 6.5862 10.5831 5.42816 12.2847C5.24354 12.5537 5.14844 12.8392 5.20438 13.163C5.30508 13.7449 5.75823 14.2005 6.32326 14.2828C6.91627 14.3706 7.41417 14.1236 7.69949 13.5692C8.62816 11.7468 9.55123 9.9244 10.4687 8.0965C10.5582 7.92084 10.6477 7.84399 10.8603 7.84399C12.354 7.83851 13.417 6.45523 13.0142 5.05C12.947 4.80848 12.8016 4.58891 12.6729 4.30896C12.5778 4.42972 12.5442 4.47364 12.5163 4.51755C12.2477 4.96766 11.9736 5.41229 11.7163 5.86789C11.5932 6.08746 11.4477 6.12039 11.2407 5.99414C11.017 5.8624 10.7876 5.73615 10.5638 5.6099C10.0491 5.31348 10.0547 5.31348 10.3512 4.80299C10.6086 4.37483 10.8547 3.94667 11.1456 3.45265Z'
      fill='white'
    />
    <path
      d='M15.6878 9.99575C15.9787 9.68287 16.2081 9.47977 16.6557 9.52917C17.7801 9.64444 18.6864 8.97476 19.1172 7.81654C19.6878 6.27957 19.0277 4.3364 17.6347 3.45264C16.6668 2.83785 15.5703 2.89275 14.6808 3.60085C14.6529 3.62281 14.6249 3.64476 14.5633 3.68319C14.485 3.5734 14.4067 3.48009 14.3452 3.37031C14.2389 3.17818 14.2836 3.04095 14.4794 2.9147C15.5591 2.21209 16.6668 2.19562 17.7801 2.79394C19.285 3.60085 20.2137 5.5056 19.9564 7.17431C19.7941 8.23372 19.3633 9.13944 18.4235 9.73776C17.5843 10.2757 16.6724 10.3251 15.6878 9.99575Z'
      fill='white'
    />
    <path
      d='M6.86463 12.2408C6.91498 12.2848 7.01008 12.3287 7.03246 12.3945C7.12757 12.68 7.21148 12.9709 7.26743 13.2673C7.27861 13.3332 7.20029 13.4649 7.13875 13.4814C6.84785 13.5802 6.55134 13.6571 6.25484 13.7174C6.18771 13.7284 6.04225 13.6406 6.01987 13.5692C5.92477 13.2948 5.85204 13.0093 5.7905 12.7239C5.77931 12.6635 5.83526 12.5318 5.8912 12.5153C6.19889 12.4165 6.51218 12.3342 6.86463 12.2408Z'
      fill='white'
    />
  </svg>
);

export default HelpSvg;
